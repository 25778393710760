import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://pucem.edu.ec/">
        PUCEM
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const PageFooter = () => {
  const classes = useStyles();

  return (
    <>
      <footer className={classes.footer}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" align="center" gutterBottom>
            <strong>Pontificia Universidad Católica del Ecuador Sede Manabí</strong>
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
              ¡Deja tu huella!
            </Typography>
            <Copyright />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" align="center" gutterBottom>
             <strong>Soporte</strong>
            </Typography>
            <Typography variant="subtitle1" align="center" component="p">
              soporte@pucesm.edu.ec
            </Typography>
            <Typography variant="subtitle1" align="center" component="p">
              Teléfono : (+593) 53700750 ext 6071, 6070, 6001
            </Typography>
            <Typography variant="subtitle1" align="center" component="p">
              Cdla. Primero de Mayo, Calle Eudoro Loor y 25 de Diciembre, Departamento de Informática
            </Typography>
          </Grid>
        </Grid>
      </footer>
    </>
  )
}

export default PageFooter;

