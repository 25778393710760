import axios from "axios";

const {REACT_APP_EP_URL} = process.env;

class PaymentsServices {

  getCheckoutId(total, fistname_owner, secondname_owner, lastname_owner, codigo_owner, email_owner, codigo_telefono_owner, phone_owner, 
    direction_owner, tipo_name, type_pay_name, course_name, tipo, type_pay, course, codigo, name, email, codigo_telefono, phone, 
    typeDocument, typeDocument_owner, lastname, description, ipv4, proveedor_pago) {
    return axios.get(REACT_APP_EP_URL + `/payment/get-checkoutId?total=${total}&fistname_owner=${fistname_owner}&secondname_owner=${secondname_owner}
                                          &lastname_owner=${lastname_owner}&codigo_owner=${codigo_owner}&email_owner=${email_owner}&codigo_telefono_owner=${codigo_telefono_owner}&phone_owner=${phone_owner}
                                          &direction_owner=${direction_owner}&tipo_name=${tipo_name}&type_pay_name=${type_pay_name}&course_name=${course_name}
                                          &tipo=${tipo}&type_pay=${type_pay}&course=${course}&codigo=${codigo}&name=${name}&email=${email}&codigo_telefono=${codigo_telefono}&phone=${phone}
                                          &typeDocument=${typeDocument}&typeDocument_owner=${typeDocument_owner}&lastname=${lastname}&description=${description}
                                          &ipv4=${ipv4}&proveedor_pago=${proveedor_pago}`);
  }

  getPaymentSuccess(id) {
    return axios.get(REACT_APP_EP_URL + `/payment/get-payment?id=${id}`);
  }

  modifyStatusTransaction(transactionId, status, date_payment) {
    return axios.patch(REACT_APP_EP_URL + `/payment/${transactionId}/status-transaction/`, {status, date_payment});
  }

  sendEmailConfirmation(processId) {
    return axios.get(REACT_APP_EP_URL + `/payment/send-email-confirmation?id=${processId}`);
  }

  sendEmailCancel(processId) {
    return axios.get(REACT_APP_EP_URL + `/payment/send-email-cancel?id=${processId}`);
  }

  getProveedorPago() {
    return axios.get(REACT_APP_EP_URL + `/parametros/getParameter/?code_parameter=1`);
  }

  getSessionPagoPlacetopay(reference) {
    return axios.get(REACT_APP_EP_URL + `/payment/get-session-pago-placetopay/?reference=${reference}`);
  }

  Proceedpayment(requestId_placetopay) {
    return axios.get(REACT_APP_EP_URL + `/payment/proceed-payment/?requestId_placetopay=${requestId_placetopay}`);
  }
}
  
export default new PaymentsServices();