import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import NavPage from './Nav';
import PageFooter from './Footer';
import FormSelection from './FormSelection';


const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function Terms() {
  const classes = useStyles();
  var sede = "Pontificia Universidad Católica del Ecuador Sede Manabí";
  return (
    <div>
      <CssBaseline />
      <NavPage />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
              PREGUNTAS FRECUENTES SOBRE PAGOS ELECTRÓNICOS
            </Typography>

          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>

            <Grid item xs={12} >
              <p><strong>1.	¿Qué es Placetopay?</strong></p>
              <p align="justify">Placetopay es la plataforma de pagos electrónicos que usa <strong>{sede}</strong> para procesar en línea las transacciones generadas en la tienda virtual con las formas de pago habilitadas para tal fin.</p>
              <br/>
              <p><strong>2.	¿Cómo puedo pagar?</strong></p>
              <p align="justify">En la tienda virtual de <strong>{sede}</strong> usted podrá realizar su pago con los medios habilitados para tal fin. Usted, de acuerdo a las opciones de pago escogidas por el comercio, podrá pagar a través Diners, Discover, Visa y MasterCard de todos los bancos con pago corriente y en los diferido, únicamente las tarjetas emitidas por Banco Pichincha, Diners, Loja, BGR y Manabí.</p>
              <br/>
              <p><strong>3.	¿Es seguro ingresar mis datos bancarios en este sitio web?</strong></p>
              <p align="justify">Para proteger tus datos <strong>{sede}</strong> delega en Placetopay la captura de la información sensible. Nuestra plataforma de pagos cumple con los más altos estándares exigidos por la norma internacional PCI DSS de seguridad en transacciones con tarjeta de crédito. Además tiene certificado de seguridad SSL expedido por GeoTrust una compañía Verisign, el cual garantiza comunicaciones seguras mediante la encriptación de todos los datos hacia y desde el sitio; de esta manera, te podrás sentir seguro a la hora de ingresar la información de su tarjeta.</p>
              <p align="justify">Durante el proceso de pago, en el navegador se muestra el nombre de la organización autenticada, la autoridad que lo certifica y la barra de dirección cambia a color verde.</p>
              <p align="justify">Estas características son visibles de inmediato y dan garantía y confianza para completar la transacción en Placetopay.</p>
              <p align="justify">Placetopay también cuenta con el monitoreo constante de McAfee Secure y la firma de mensajes electrónicos con Certicámara.</p>
              <p align="justify">Placetopay es una marca de la empresa colombiana EGM Ingeniería Sin Fronteras S.A.S. </p>
              <br/>
              <p><strong>4.	¿Puedo realizar el pago cualquier día y a cualquier hora?</strong></p>
              <p align="justify">Sí, en <strong>{sede}</strong> podrás realizar tus compras en línea los 7 días de la semana, las 24 horas del día a sólo un clic de distancia.</p>
              <br/>
              <p><strong>5.	¿Puedo cambiar la forma de pago?</strong></p>
              <p align="justify">Si aún no has finalizado tu pago, podrás volver al paso inicial y elegir la forma de pago que prefieras. Una vez finalizada la compra no es posible cambiar la forma de pago.</p>
              <br/>
              <p align="justify"><strong>6.	¿Pagar electrónicamente tiene algún valor para mí como comprador?</strong></p>
              <p>No, los pagos electrónicos realizados a través de Placetopay no generan costos adicionales para el comprador.</p>
              <br/>
              <p><strong>7.	¿Qué debo hacer si mi transacción no concluyó?</strong></p>
              <p align="justify">En primera instancia deberás revisar si llegó un mail de confirmación del pago en tu cuenta de correo electrónico (la inscrita en el momento de realizar el pago), en caso de no haberlo recibido, deberás contactar a <strong>{sede}</strong> para confirmar el estado de la transacción.</p>
              <p align="justify">En caso que tu transacción haya declinado, debes verificar si la información de la cuenta es válida, está habilitada para compras no presenciales y si tienes cupo o saldo disponible. Si después de esto continua con la declinación debes comunicarte con <strong>{sede}</strong>. En última instancia, puedes remitir tu solicitud a <strong>servicioposventa@placetopay.ec</strong>.</p>
              <br/>
              <p><strong>8.	¿Qué debo hacer si no recibí el comprobante de pago?</strong></p>
              <p align="justify">Por cada transacción aprobada a través de Placetopay, recibirás un comprobante del pago con la referencia de compra en la dirección de correo electrónico que indicaste al momento de pagar.  Si no lo recibes, podrás contactar a la línea <strong>(+593) 53700750 ext 6071, 6070, 6001</strong> o al correo electrónico <strong>soporte@pucesm.edu.ec</strong>, para solicitar el reenvío del comprobante a la misma dirección de correo electrónico registrada al momento de pagar. En última instancia, puedes remitir tu solicitud a <strong>servicioposventa@placetopay.ec</strong>.</p>
              <br/>
              <p><strong>9. No me llegó el producto que compré ¿qué hago?</strong></p>
              <p align="justify">Debes verificar si la transacción fue exitosa en tu extracto bancario. En caso de ser así, debes revisar nuestras políticas de envío en el sitio web <strong><a href="https://pucem.edu.ec" target="_BLANK">PUCE SEDE MANABÍ</a></strong> para identificar los tiempos de entrega.</p>
              
            </Grid>

          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <PageFooter />
      {/* End footer */}
    </div>
  );
}