import axios from "axios";

const {REACT_APP_EP_URL} = process.env;

class StudentsServices {

  search(search) {
    return axios.get(REACT_APP_EP_URL + `/student/search?search=${search}`);
  }

}
  
export default new StudentsServices();