import React, {useState} from 'react';

const ContextEnviroment = React.createContext();

const ProviderEnviroment = ({children}) => {
    const [enviroment, setEnviroment] = useState({})
    const [parameter, setParameter] = useState({})

    const store = {
        enviroment: [enviroment, setEnviroment],
        parameter: [parameter, setParameter],
      }
    
    return (
        <ContextEnviroment.Provider value = {store}>
            {children}
        </ContextEnviroment.Provider>
    );
}

export {ContextEnviroment, ProviderEnviroment};