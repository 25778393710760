import React, { useEffect } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MuiAlert from '@material-ui/lab/Alert';
import NavPage from './Nav';
import PageFooter from './Footer';
import {Backdrop, CircularProgress, Snackbar} from '@material-ui/core';
import FormSelection from './FormSelection';
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded'
import ErrorRounded from '@material-ui/icons/ErrorRounded'
import CancelRounded from '@material-ui/icons/CancelRounded'
import InsertDriveFileRounded from '@material-ui/icons/InsertDriveFileRounded'
import LocalAtmRounded from '@material-ui/icons/LocalAtmRounded'
import DomainRounded from '@material-ui/icons/DomainRounded'
import BrandingWatermarkRounded from '@material-ui/icons/BrandingWatermarkRounded'
import AccountBoxRounded from '@material-ui/icons/AccountBoxRounded'
import EmailRounded from '@material-ui/icons/EmailRounded'
import NearMeRounded from '@material-ui/icons/NearMeRounded'
import { useParams } from "react-router-dom";
import PaymentsServices from "../services/payments.service";
import {
  Button
} from '@material-ui/core';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
}
}));

export default function Terms() {
  const classes = useStyles();
  const { reference } = useParams();
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  useEffect(() => {
    getSessionPagoPlacetopay(reference);
  }, [reference]);

  const handleLoadingClose = () => {
      setLoading(false);
  };

  const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
      }
      setOpen(false);
  };

  const getSessionPagoPlacetopay = async (referencia) => {
    setLoading(true);
    PaymentsServices.getSessionPagoPlacetopay(referencia)
        .then(response => {
          setLoading(false);
          const data = response.data;
          if(data!=null){
            if(data.success){
              setData(data)
            }else{
              setAlert({ 'severity': 'warning', 'msg': data.msg });
              setOpen(true);
            }
          }else{
            setAlert({ 'severity': 'warning', 'msg': 'No existen los datos!' });
            setOpen(true);
          }
            
        })
        .catch(e => {
          setLoading(false);
          console.log(e);
        });
  }

  return (
    <div>
      <CssBaseline />
      <NavPage />
      <main>
        {/* Hero unit */}
        <Container className={classes.cardGrid} maxWidth="md">
        <Card className={classes.root} style={{marginTop: "20px"}}>
                                <CardContent>
                                    <Typography gutterBottom color="textSecondary" variant="h5" component="h2" style={{justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                                    <strong>Detalles del Pago</strong>
                                    </Typography>
                                    <br/>
                                    <div style={{marginLeft: "100px"}}>
                                    <Grid container wrap="nowrap" spacing={2} style={{justifyContent: "left|center", display: "flex", alignItems: "center", textAlign: "center"}}>
                                        <Grid item>
                                            <span style={{marginRight: "10px", justifyContent: "center", display: "flex", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            <DomainRounded style={{ color: 'gray ' }} />  
                                        </span>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" component="h2" noWrap><strong>Sitio</strong><span style={{marginLeft: "10px", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            Pontificia Universidad Católica del Ecuador Sede Manabí 
                                                        </span></Typography>
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <Grid container wrap="nowrap" spacing={2} style={{justifyContent: "left|center", display: "flex", alignItems: "center", textAlign: "center"}}>
                                        <Grid item>
                                            <span style={{marginRight: "10px", justifyContent: "center", display: "flex", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            <InsertDriveFileRounded style={{ color: 'gray ' }} />  
                                        </span>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" component="h2" noWrap><strong>Referencia</strong><span style={{marginLeft: "10px", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            {data.referencia} 
                                                        </span></Typography>
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <Grid container wrap="nowrap" spacing={2} style={{justifyContent: "left|center", display: "flex", alignItems: "center", textAlign: "center"}}>
                                        <Grid item>
                                        <span style={{marginRight: "10px", justifyContent: "center", display: "flex", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            <LocalAtmRounded style={{ color: 'gray ' }} />  
                                        </span>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" component="h2" noWrap><strong>Valor</strong><span style={{marginLeft: "10px", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            USD ${data.valor}
                                                        </span></Typography>
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <Grid container wrap="nowrap" spacing={2} style={{justifyContent: "left|center", display: "flex", alignItems: "center", textAlign: "center"}}>
                                        <Grid item>
                                        <span style={{marginRight: "10px", justifyContent: "center", display: "flex", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            <BrandingWatermarkRounded style={{ color: 'gray ' }} />  
                                        </span>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" component="h2" noWrap><strong>Documento</strong><span style={{marginLeft: "10px", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            {data.documento}
                                                        </span></Typography>
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <Grid container wrap="nowrap" spacing={2} style={{justifyContent: "left|center", display: "flex", alignItems: "center", textAlign: "center"}}>
                                        <Grid item>
                                        <span style={{marginRight: "10px", justifyContent: "center", display: "flex", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            <AccountBoxRounded style={{ color: 'gray ' }} />  
                                        </span>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" component="h2" noWrap><strong>Nombres/Apellidos</strong><span style={{marginLeft: "10px", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            {data.nombres_apellidos}
                                                        </span></Typography>
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <Grid container wrap="nowrap" spacing={2} style={{justifyContent: "left|center", display: "flex", alignItems: "center", textAlign: "center"}}>
                                        <Grid item>
                                        <span style={{marginRight: "10px", justifyContent: "center", display: "flex", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            <EmailRounded style={{ color: 'gray ' }} />  
                                        </span>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" component="h2" noWrap><strong>Correo</strong><span style={{marginLeft: "10px", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            {data.correo}
                                                        </span></Typography>
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <Grid container wrap="nowrap" spacing={2} style={{justifyContent: "left|center", display: "flex", alignItems: "center", textAlign: "center"}}>
                                        <Grid item>
                                        <span style={{marginRight: "10px", justifyContent: "center", display: "flex", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            <NearMeRounded style={{ color: 'gray ' }} />  
                                        </span>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" component="h2" noWrap><strong>Dirección</strong><span style={{marginLeft: "10px", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                            {data.direccion}
                                                        </span></Typography>
                                        </Grid>
                                    </Grid>
                                    </div>                               
                                    <br/>
                                    <br/>
                                    <br/>
                                    {data.estado_transaccion==1 ?
                                      <Typography variant="h5" component="h2" style={{justifyContent: "center", display: "flex", alignItems: "left", textAlign: "left"}}>
                                          <span style={{marginRight: "10px", justifyContent: "center", display: "flex", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                              <ErrorRounded style={{ color: 'gray ' }} />  
                                          </span>
                                          <strong>Transacción Pendiente</strong> 
                                      </Typography>
                                    : (data.estado_transaccion==2 ?
                                        <Typography variant="h5" component="h2" style={{justifyContent: "center", display: "flex", alignItems: "left", textAlign: "left"}}>
                                            <span style={{marginRight: "10px", justifyContent: "center", display: "flex", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                                <CancelRounded style={{ color: 'red' }} />  
                                            </span>
                                            <strong>Transacción Rechazada</strong> 
                                        </Typography>
                                      : (data.estado_transaccion==3 ?
                                        <Typography variant="h5" component="h2" style={{justifyContent: "center", display: "flex", alignItems: "left", textAlign: "left"}}>
                                          <span style={{marginRight: "10px", justifyContent: "center", display: "flex", alignItems: "center", textAlign: "center"}} class="svg-inline">
                                              <CheckCircleRounded style={{ color: 'green' }} />  
                                          </span>
                                          <strong>Transacción Aprobada</strong> 
                                      </Typography>
                                      : <></>))
                                    }
                                    <Grid className={classes.marginTopGrid} container justify="flex-end" alignItems="center" style={{marginTop: "50px"}}>
                                    <a href="/" rel="noreferrer">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}>
                                        Regresar al Comercio
                                    </Button>
                                    </a>
                                    
                                </Grid>
                                </CardContent>
                                </Card>
                                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                    <Alert onClose={handleClose} severity={alert.severity}>
                                        {alert.msg}
                                    </Alert>
                                </Snackbar>
                                <Backdrop className={classes.backdrop} open={loading} onClick={handleLoadingClose}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
        </Container>
        
      </main>
      {/* Footer */}
      <PageFooter />
      {/* End footer */}
    </div>
  );
}