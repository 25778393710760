import React from 'react';
import ReactDOM from 'react-dom';
import Index from './components/Index';
import {ProviderEnviroment} from './context/contextEnviroment';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Status from './components/Status';
import Terms from './components/Terms';
import Politics from './components/Politics';
import PoliticsDataProteccion from './components/PoliticsDataProteccion';
import FrequentQuestion from './components/frequent_questions';
import PageReturnPayment from './components/page_return_payment';

const App = () => {
  return (
    <>
      <Router>
        <ProviderEnviroment>
          <Switch>
            <Route exact path="/" component={Index}></Route>
            <Route path="/payment" component={Status}></Route>
            <Route path="/payment" component={Status}></Route>
            <Route path="/terminos-y-condiciones" component={Terms}></Route>
            <Route path="/politica-proteccion-datos" component={PoliticsDataProteccion}></Route>
            <Route path="/politicas-de-privacidad" component={Politics}></Route>
            <Route path="/preguntas-frecuentes" component={FrequentQuestion}></Route>
            <Route path="/page_return_payment/:reference" component={PageReturnPayment}></Route>
          </Switch>
        </ProviderEnviroment>
      </Router>
    </>
  )
}


ReactDOM.render(<App />, document.getElementById('root'));

