import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import NavPage from './Nav';
import PageFooter from './Footer';
import FormSelection from './FormSelection';


const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function PoliticsDataProteccion() {
  const classes = useStyles();

  return (
    <div>
      <CssBaseline />
      <NavPage />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                Protección de Datos Personales
            </Typography>

          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>

            <Grid item xs={12} >
              <p align="justify">El titular de los datos acepta y emite su consentimiento unilateral de manera libre y voluntaria para el tratamiento de sus datos personales para que sean: recolectados a través de nuestros canales electrónicos o por cualquier medio, almacenados, procesados, transmitidos y eliminados o para ser objeto de cualquier tratamiento o conjunto de tratamientos a realizar, ya sea por procedimientos técnicos de carácter automatizado, parcialmente automatizado o no automatizados por el Comercio o su delegado, con la finalidad de identificar, actualizar sus datos y ofrecerle servicios y/o productos a través de los canales digitales o cualquier otro medio.</p>
              <p align="justify">La transmisión y almacenamiento de la información recopilada por el Comercio o su delegado, será realizada en la infraestructura que este administre, dentro o fuera del territorio ecuatoriano. Esta infraestructura puede ser implementada en servidores físicos o en la nube de proveedores que cuenten con las debidas certificaciones internacionales para el tratamiento de Seguridad de la Información.</p>
              <p align="justify">Adicionalmente, mediante la presente el titular de los datos declara conocer y aceptar el Aviso de Política de Privacidad de Place To Play en la cual se indican sus derechos, datos tratados y las actividades comerciales de los aliados, así como otros datos de interés respecto a la protección de datos personales, que se encuentran detallados en el sitio web de Place To Play en la ruta <strong><a href="https://www.placetopay.com/web/politicas-de-privacidad/" target="_BLANK">https://www.placetopay.com/web/politicas-de-privacidad/</a></strong>, mediante la cual, se podrá solicitar la gestión de derechos o consentimientos.</p>
              <p align="justify">El Comercio o su delegado notificará sobre las actualizaciones del Aviso de Política de Privacidad antes mencionado por medio de los diferentes canales digitales.</p>
            </Grid>

          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <PageFooter />
      {/* End footer */}
    </div>
  );
}