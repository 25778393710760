import axios from "axios";

const {REACT_APP_EP_URL} = process.env;

class CoursesServices {

    getCoursesType(id) {
      return axios.get(REACT_APP_EP_URL + `/courses/list/?id_type=${id}`);
    }

    getCheckoutId(total) {
      return axios.get(REACT_APP_EP_URL + `/payment/get-checkoutId?total=${total}`);
    }
     
    create(data) {
      return axios.post(REACT_APP_EP_URL + "/tutorials", data);
    }
  
    update(id, data) {
      return axios.put(REACT_APP_EP_URL + `/tutorials/${id}`, data);
    }
  
    delete(id) {
      return axios.delete(REACT_APP_EP_URL + `/tutorials/${id}`);
    }

  }
  
  export default new CoursesServices();