import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import NavPage from './Nav';
import PageFooter from './Footer';
import FormSelection from './FormSelection';


const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function Terms() {
  const classes = useStyles();

  return (
    <div>
      <CssBaseline />
      <NavPage />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Términos y Condiciones
            </Typography>

          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>

            <Grid item xs={12} >
              <p><strong>Introducción</strong></p>
              <p>Estos Términos y Condiciones estándar del sitio web escritos en esta página administrarán su uso de esta plataforma de pagos en línea, accesible a PUCE Sede Manabí.</p>
              <p>Estos Términos se aplicarán completamente y afectarán su uso de este servicio web. Al usar este sitio web, usted acordó aceptar todos los términos y condiciones escritos aquí. No debe usar esta plataforma de pago si no está de acuerdo con alguno de estos Términos y Condiciones del sitio web. </p>
              <p>Personas menores de 18 años no pueden usar este sitio web.</p>
              <p><strong>Derechos de propiedad intelectual</strong></p>
              <p>Además del contenido que posee, bajo estos Términos, PUCE SEDE MANABI y / o sus licenciantes poseen todos los derechos de propiedad intelectual y materiales contenidos en esta plataforma de pago.</p>
              <p><strong>Restricciones</strong></p>
              <p>Usted está restringido específicamente de todo lo siguiente:</p>
              <ul>
                <li>Usar este sitio web de cualquier manera que sea o pueda ser perjudicial para este sitio web</li>
                <li>Usar este sitio web de cualquier manera que afecte el acceso del usuario a este sitio web</li>
                <li>El uso de este sitio web contrario a las leyes y regulaciones aplicables, o de cualquier manera puede causar daño al sitio web, a cualquier persona o entidad comercial</li>
                <li>Participar en cualquier extracción de datos, recolección de datos, extracción de datos o cualquier otra actividad similar en relación con este sitio web</li>
                <li>Usar este sitio web para participar en cualquier publicidad o marketing</li>
              </ul>
              <p><strong>Tu privacidad</strong></p>
              <p>Por favor lea la <a href="/politicas-de-privacidad">política de privacidad</a>.</p>
              <p><strong>Limitación de responsabilidad</strong></p>
              <p>En ningún caso, PUCE SEDE MANABI, ni ninguno de sus funcionarios, directores y empleados, serán responsables de todo lo que surja o esté relacionado de alguna manera con su uso de esta plataforma de pago, ya sea que dicha responsabilidad esté bajo contrato. PUCE SEDE MANABI, incluidos sus funcionarios, directores y empleados, no se hace responsable de ninguna responsabilidad indirecta, consecuente o especial que surja o esté relacionada de alguna manera con su uso de este servicio de pago.</p>
              <p><strong>Indemnización</strong></p>
              <p>Por la presente, indemniza en la mayor medida posible a PUCE SEDE MANABI de y contra todas y cada una de las responsabilidades, costos, demandas, causas de acción, daños y gastos que surjan de cualquier manera relacionada con el incumplimiento de cualquiera de las disposiciones de estos Términos.</p>
              <p><strong>Divisibilidad</strong></p>
              <p>Si se determina que alguna disposición de estos Términos no es válida según alguna ley aplicable, dichas disposiciones se eliminarán sin afectar las disposiciones restantes del presente.</p>
              <p><strong>Variación de términos</strong></p>
              <p>A PUCE SEDE MANABI se le permite revisar estos Términos en cualquier momento que lo considere oportuno, y al usar este sitio web se espera que los revise periódicamente.</p>
              <p><strong>Asignación</strong></p>
              <p>El PUCE SEDE MANABI tiene permitido asignar, transferir y subcontratar sus derechos y / u obligaciones bajo estos Términos sin ninguna notificación. Sin embargo, no está permitido asignar, transferir o subcontratar ninguno de sus derechos y / u obligaciones en virtud de estos Términos.</p>
              <p><strong>Acuerdo completo</strong></p>
              <p>Estos Términos constituyen el acuerdo completo entre PUCE SEDE MANABI y usted en relación con su uso de este servicio de pago, y reemplazan todos los acuerdos y entendimientos anteriores.</p>

            </Grid>

          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <PageFooter />
      {/* End footer */}
    </div>
  );
}