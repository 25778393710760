import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import HelpRounded from '@material-ui/icons/HelpRounded'
import Link from '@material-ui/core/Link';
import {proveedorPago} from './Index';
import { ContextEnviroment } from '../context/contextEnviroment'
const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
}));

const NavPage = () => {
    const classes = useStyles();
    const {
        ['parameter']: [data, setData],
      } = React.useContext(ContextEnviroment)
    return (
        <>
            <AppBar position="relative">
                <Toolbar>
                <Link href="/" className={classes.link} >
                    <Avatar alt="PUCEM" className={classes.icon}  src={window.location.origin + '/logo-p.png'} />
                </Link>
                
                <Typography variant="h6" style={{flexGrow: 1}} color="inherit" noWrap>
                    <strong>PAGOS EN LÍNEA PUCE MANABÍ</strong>
                </Typography>
                {data.parametro==2 ? 
                    <IconButton color="inherit" onClick={() => window.open('/preguntas-frecuentes', "_blank")}>
                        <HelpRounded />
                    </IconButton>
                :
                    <></>
                }
                
                </Toolbar>
                
            </AppBar>
        </>
    )
}

export default NavPage;